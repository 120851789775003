<template>
  <b-row>
    <b-col md="4" lg="4" v-for="stat in stats" :key="stat.id">
      <b-card v-if="stat.img" class="text-center">
        <b-avatar square :src="stat.img" size="7rem"></b-avatar>
      </b-card>

      <stat
        v-else
        :variant="stat.variant"
        :icon="stat.icon"
        :name="stat.name"
        :value="stat.value"
        :unit="stat.unit"
      ></stat>
    </b-col>
  </b-row>
</template> 
      
      <script>
import
{
  BRow,
  BCol,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BCard,
  VBPopover,
  BAvatar,

} from "bootstrap-vue";

import axios from '@axios'

import Stat from '@/views/clients/base/components/Stat.vue'

import VueSlider from 'vue-slider-component'




export default {
  components: {
    BRow,
    BCol,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCard,
    BAvatar,
    Stat

  },

  directives: {
  },

  watch: {
    dataUrl: function (newVal, oldVal)
    {
      //console.log('Prop changed: ', newVal, ' | was: ', oldVal)

      if (this.firstLoaded)
      {
        this.updateElement()
      }


    }
  },



  data: function ()
  {

    return {


      isLoading: true,

      firstLoaded: false,
      stats: []


    };
  },
  created()
  {
    this.updateElement()

  },

  mounted()
  {

  },

  methods: {

    getData()
    {

      return axios.request({
        method: "get",
        url: this.dataUrl,
        headers: {
          Accept: "application/json",
        },
      })

    },

    updateElement()
    {
      if (!this.firstLoaded)
      {
        this.isLoading = true;
      }

      this.getData()
        .then((response) =>
        {
          this.stats = response.data

          if (!this.firstLoaded)
          {
            this.isLoading = false;
            this.firstLoaded = true;
          }

        })

    },




  },


  props: {
    dataUrl: {
      type: String,
      required: true,
    },
  },

};
      </script>
      
      <style lang="scss"></style>
      