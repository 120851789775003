<template>
  <section>
    <b-row>
      <b-col md="3" lg="3" v-for="stat in stats00Data" :key="stat.id">
        <stat
          :variant="stat.variant"
          :icon="stat.icon"
          :name="stat.name"
          :value="stat.value"
          :unit="stat.unit"
        ></stat>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <bruna-element-loader :dataUrl="evolucionVariablesChart01" loadingStrategy="ALWAYS">
        </bruna-element-loader>
      </b-col>
    </b-row>    


    <b-row>
      <b-col>
        <bruna-element-loader :dataUrl="evolucionVariablesChart00" loadingStrategy="ALWAYS">
        </bruna-element-loader>
      </b-col>
    </b-row>    

   
  </section>
</template> 
        
        <script>

import CardTooltip from '@/views/brunacomponents/wrappers/CardTooltipWrapper.vue';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'

import Stat from '@/views/clients/base/components/Stat.vue'

import Stats from './Stats.vue';

import moment from 'moment';

import HeaderCellRenderer from '@/views/brunacomponents/ag-grid-table/cell-renderers/HeaderCellRenderer.vue';

import { AgGridVue } from "ag-grid-vue";

import axios from '@axios'

import
{
  BTabs,
  BTab,
  BOverlay,
  BSpinner,
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormTags,
  BFormCheckboxGroup,
  BFormCheckbox,

  BListGroup,
  BListGroupItem,
  BProgress
} from "bootstrap-vue";

var qs = require('qs');
import useApiServices from '@/services/useApiServices.js';


export default {



  data()
  {
    let id = this.$route.query.id;

    let query = qs.stringify({
      id: id
    })

    return {
      showOverlay: false,


      stats00Url: useApiServices.detalleTurno.stats00 + "?" + query,
      stats00Data: [],

      evolucionVariablesChart00: useApiServices.detalleTurno.evolucionVariablesChart00 + "?" + query,
      evolucionVariablesChart01: useApiServices.detalleTurno.evolucionVariablesChart01 + "?" + query,


    };
  },



  mounted()
  {


  },

  created()
  {

    this.loadStats();

  },

  components: {
    BTabs,
    BTab,
    BOverlay,
    BSpinner,
    BrunaElementLoader,
    AgGridTable,
    AgGridVue,


    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,

    Stat,
    BFormTags,

    BProgress,

    BListGroup,
    BListGroupItem,

    headerCellRenderer: HeaderCellRenderer,

    CardTooltip,
    Stats,

    BFormCheckboxGroup,
    BFormCheckbox


  },

  methods: {


    loadStats()
    {
      axios.request({
        method: "get",
        url: this.stats00Url,
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) =>
        {
          this.stats00Data = response.data
        })


    },




  },

};
        </script>
    
    
    
      
      <style lang="scss" scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
//@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
        
      <style >
.btn-padding {
  margin-right: 48px;
}

.m-estadisticas {
  margin-left: 20px;
  margin-bottom: 1%;
  margin-top: 1%;
}
.icon-plan {
  padding-top: 5%;
}

.ag-row-pinned {
  background-color: #f0e68c !important;
}
</style>
        
      
      <style>
div.ag-watermark {
  display: none !important;
}
</style>
      